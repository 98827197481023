export const getFlexPosition = (
  position: string
): "center" | "flex-end" | "flex-start" => {
  switch (position) {
    case "top":
      return "flex-start";
    case "bottom":
      return "flex-end";
    case "center":
      return "center";
    default:
      return "center";
  }
};
