import React from "react";

export const arrowIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.669"
    height="13.402"
    viewBox="0 0 27.669 13.402"
  >
    <g transform="translate(27.669 145.402) rotate(180)">
      <g transform="translate(0 132)">
        <path
          className="slider-control-arrow"
          d="M27.352,137.936h0l-5.647-5.62a1.081,1.081,0,0,0-1.525,1.532l3.792,3.773H1.081a1.081,1.081,0,1,0,0,2.162H23.97l-3.792,3.773a1.081,1.081,0,0,0,1.525,1.532l5.647-5.62h0A1.082,1.082,0,0,0,27.352,137.936Z"
          transform="translate(0 -132)"
        />
      </g>
    </g>
  </svg>
);
