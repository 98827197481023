import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { arrowIcon } from "./icons";
import { SliderElement, SliderTile } from "./types";
import SlideItem from "./components/SlideItem";
import Media from "react-media";
import { isMobile } from "mobile-device-detect";
import { getFlexPosition } from "./positionMapper";
import { IDynamicBlockComponent } from "../../dynamicPageBuilder/IDynamicBlockComponent";
import { useRouter } from "next/router";

type MainSliderProps = {
  element: Record<string, SliderElement>;
  tiles: Array<SliderTile>;
  positionDesktop?: string;
  positionMobile?: string;
  delay: number;
};

const MainSlider: React.FunctionComponent<IDynamicBlockComponent> = ({
  blockData,
}): React.ReactElement => {
  const { locale: lang } = useRouter();
  const {
    data: { element = {}, tiles = {}, positionDesktop, positionMobile, delay },
  } = blockData as { data: MainSliderProps };

  const slides: Array<SliderElement> = Object.values(element);
  const tilesForSlides: Array<SliderTile> = Object.values(tiles);

  const hasOnlyOneSlide = slides.length === 1;
  const renderArrowNext = (
    clickHandler: () => void,
    hasNext: boolean
  ): React.ReactElement => {
    const classNameForButton = `slider-control-arrow-button next${
      hasNext ? "" : " disabled"
    }`;

    return (
      <button
        aria-label="Next slide"
        className={classNameForButton}
        onClick={clickHandler}
      >
        {arrowIcon}
      </button>
    );
  };

  const renderArrowPrev = (
    clickHandler: () => void,
    hasNext: boolean
  ): React.ReactElement => {
    const classNameForButton = `slider-control-arrow-button prev${
      hasNext ? "" : " disabled"
    }`;
    return (
      <button
        aria-label="Previous slide"
        className={classNameForButton}
        onClick={clickHandler}
      >
        {arrowIcon}
      </button>
    );
  };

  const getTilesForSlide = (slidePosition: number): Array<SliderTile> =>
    tilesForSlides.filter((tile) => tile.parent_slide === slidePosition);

  const getSlidesForCarouselDesktop = (): Array<React.ReactElement> =>
    slides.map((slide, index) => (
      <SlideItem
        key={`slide_${index}`}
        allSlides={slides}
        currentSlide={slide}
        slideIndex={index}
        tiles={getTilesForSlide(index + 1)}
        position={positionDesktop}
        hasBottomPadding={!!tilesForSlides.length && tilesForSlides.length > 0}
        lang={lang as string}
      />
    ));

  const getSlidesForCarouselMobile = (): Array<React.ReactElement> =>
    slides.map((slide, slideIndex) => (
      <Media
        key={`slide_${slideIndex}`}
        query="(orientation: landscape)"
        defaultMatches={false}
      >
        {(matches) => (
          <div
            className="main-slider__slide"
            style={{
              backgroundImage: `url(${
                process.env.NEXT_PUBLIC_IMG_URL +
                (matches
                  ? slide?.picture.publicUrl
                  : slide.picture_mobile
                  ? slide.picture_mobile.publicUrl
                  : slide.picture.publicUrl)
              })`,
              backgroundSize: "cover",
            }}
          >
            <div className="slide__content slide__content--mobile">
              <div
                className="slide__contentContainer slide__contentContainer--mobile"
                style={{
                  justifyContent: getFlexPosition(positionMobile as string),
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: slide.content }} />
              </div>
            </div>
          </div>
        )}
      </Media>
    ));

  return (
    <div className="main-slider">
      <Media query="(min-width: 768px)" defaultMatches={!isMobile}>
        <Carousel
          className="main-slider__desktop"
          showArrows={true}
          showThumbs={false}
          stopOnHover
          autoPlay={!!delay && delay > 0}
          interval={delay}
          showIndicators={false}
          infiniteLoop={!hasOnlyOneSlide}
          showStatus={false}
          dynamicHeight
          renderArrowNext={renderArrowNext}
          renderArrowPrev={renderArrowPrev}
        >
          {getSlidesForCarouselDesktop()}
        </Carousel>
      </Media>
      <Media query="(max-width: 767px)" defaultMatches={isMobile}>
        <Carousel
          className="main-slider__mobile"
          showArrows={false}
          showThumbs={false}
          stopOnHover
          autoPlay={!!delay && delay > 0}
          interval={delay}
          showIndicators={!hasOnlyOneSlide}
          infiniteLoop={!hasOnlyOneSlide}
          showStatus={false}
          swipeScrollTolerance={100}
          preventMovementUntilSwipeScrollTolerance
        >
          {getSlidesForCarouselMobile()}
        </Carousel>
      </Media>
    </div>
  );
};

export default MainSlider;
