import React, { useEffect, useState } from "react";
import { SliderElement, SliderTile } from "../types";
import { get } from "lodash";
import { getFlexPosition } from "../positionMapper";
import PictureProvider from "../../../components/Picture";
import { useTranslation } from "next-i18next";
import { isFullUrl } from "../../../../utils/validators";
import { chevronRight } from "../../../../utils/icons";

type SlideItemProps = {
  currentSlide: SliderElement;
  allSlides: Array<SliderElement>;
  tiles: Array<SliderTile>;
  slideIndex: number;
  position?: string;
  hasBottomPadding: boolean;
  lang: string;
};

const SlideItem: React.FunctionComponent<SlideItemProps> = ({
  allSlides,
  slideIndex,
  currentSlide,
  tiles,
  position,
  hasBottomPadding,
  lang,
}): React.ReactElement => {
  const [linkedSlideIndex, setLinkedSlideIndex] = useState<number | null>(null);
  const [activeTile, setActiveTile] = useState<number | undefined>();
  const { t } = useTranslation();

  const setDefaultSelected = (): void => {
    const activeTileIndex = tiles.findIndex((tile) => tile.selected);
    if (activeTileIndex !== -1) {
      setActiveTile(activeTileIndex);
      const linkedSlide = get(tiles[activeTileIndex], "linked_slide");
      setLinkedSlideIndex(linkedSlide);
    }
  };

  const styleForSlideContent = {
    paddingBottom: hasBottomPadding ? "115px" : 0,
    paddingTop: hasBottomPadding ? "115px" : 0,
    justifyContent: getFlexPosition(position as string),
  };

  useEffect(() => {
    setDefaultSelected();
  }, []);

  const onMouseEnter = (
    linkedSlideNumber: number | null,
    index: number
  ): void => {
    if (linkedSlideNumber) {
      setLinkedSlideIndex(linkedSlideNumber);
    }

    setActiveTile(index);
  };

  const onMouseLeave = (): void => {
    const defaultActiveTileIndex = tiles.findIndex((tile) => tile.selected);
    if (defaultActiveTileIndex !== -1) {
      setLinkedSlideIndex(tiles[defaultActiveTileIndex].linked_slide);
    } else if (linkedSlideIndex) {
      setLinkedSlideIndex(null);
    }

    setActiveTile(
      defaultActiveTileIndex !== -1 ? defaultActiveTileIndex : undefined
    );
  };

  const getTilesForSlide = (): React.ReactNode => {
    return tiles.length > 0 ? (
      <div className="cards">
        <div className="cardsContainer">
          {tiles.map((slideTile, index) => (
            <div
              key={slideTile.title}
              onMouseEnter={() => onMouseEnter(slideTile.linked_slide, index)}
              onMouseLeave={onMouseLeave}
              className={`slider-card${
                activeTile === index ? " slider-card--active" : ""
              }`}
            >
              <div
                className={`slider-card__title ${
                  slideTile.picture ? "" : "slider-card__withoutImg"
                }`}
              >
                {slideTile.picture && slideTile.picture.publicUrl && (
                  <PictureProvider data={slideTile.picture} />
                )}

                <h2>{slideTile.title}</h2>
              </div>
              <div className="slider-card__description">
                {slideTile.content}
              </div>
              {slideTile.link && (
                <div className="slider-card__action">
                  {isFullUrl(slideTile.link) ? (
                    <a
                      href={slideTile.link}
                      className="modern-button modern-button-outline-secondary modern-button-small"
                    >
                      {t("packages-block-more")} {chevronRight}
                    </a>
                  ) : (
                    <a
                      href={`/${lang}${slideTile.link}`}
                      className="modern-button modern-button-outline-secondary modern-button-small"
                    >
                      {t("packages-block-more")} {chevronRight}
                    </a>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    ) : null;
  };

  const getLinkedSlideData = (): React.ReactNode => {
    if (typeof activeTile === "number") {
      const currentTile = tiles[activeTile];
      if (currentTile && typeof currentTile.linked_slide === "number") {
        const linkedSlide = allSlides[currentTile.linked_slide - 1];
        return (
          <>
            <PictureProvider data={linkedSlide.picture} />
            <div
              className="slide__content"
              style={{ display: linkedSlideIndex ? "flex" : "none" }}
            >
              <div
                className="slide__contentContainer"
                style={styleForSlideContent}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: linkedSlide.content }}
                />
              </div>
            </div>
          </>
        );
      }
    }
  };

  return (
    <div key={`slide_${slideIndex}`} className="main-slider__slide">
      {!linkedSlideIndex && (
        <div>
          <PictureProvider data={currentSlide.picture} />
          <div className="slide__content">
            <div
              className="slide__contentContainer"
              style={styleForSlideContent}
            >
              <div dangerouslySetInnerHTML={{ __html: currentSlide.content }} />
            </div>
          </div>
        </div>
      )}
      <div
        className={
          !linkedSlideIndex
            ? "slide-fadeOut_animation"
            : "slide-fadeIn_animation"
        }
      >
        {getLinkedSlideData()}
      </div>
      {getTilesForSlide()}
    </div>
  );
};

export default SlideItem;
